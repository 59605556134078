import React, { Component } from "react";
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import './Interests.css';
import axios from 'axios';
import { config } from '../Config/Config';
import advusericon from '../images/adv-usericon.svg';
import advwebicon from '../images/adv-web.svg';
import advphoneicon from '../images/adv-phoneicon.svg';
import advemailicon from '../images/adv-email.svg';
import moment from "moment-timezone";
import SimpleCrypto from "simple-crypto-js";
import { AiOutlineShareAlt } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class DetailedAdvertisement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            campaignName: "",
            campaignUrl: "",
            campaignPaymentType: "",
            description: "",
            designation: "",
            startDate: "",
            endDate: "",
            createdAt: "",
            imageUrl: "",
            type: "",
            advertiserEmail: "",
            advertiserName: "",
            advertiserNumber: ""


        }

    }
    componentDidMount() {
        // console.log("new", this.props.match.params.id)
        this.getCampaignById();
    }
    onClickUrl = () => {
        window.open(this.state.campaignUrl)
    }
    getCampaignById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + `user/getCampaignById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
                //   console.log(response);
                if (response.data.status === true) {
                    //  console.log("success!!");
                    self.setState({
                        campaignName: response.data.campaign.campaignName,
                        advertiserName: response.data.campaign.advertiserName,
                        advertiserNumber: response.data.campaign.advertiserNumber,
                        advertiserEmail: response.data.campaign.advertiserEmail,
                        campaignPaymentType: response.data.campaign.campaignPaymentType,
                        campaignUrl: response.data.campaign.campaignUrl,
                        description: response.data.campaign.description,
                        designation: response.data.campaign.designation,
                        startDate: response.data.campaign.startDate,
                        endDate: response.data.campaign.endDate,
                        createdAt: response.data.campaign.createdAt,
                        imageUrl: response.data.campaign.imageUrl,
                        type: response.data.campaign.type,
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    shareAdv = (d) => {
        if (navigator.share) {
            navigator.share({
                url: `https://www.sceem.org/adv/${d}/${this.props.match.params.id}`,
            }).then(() => {
                // console.log('Thanks for sharing!', d);
            }).catch(err => {
                console.log("Error while using Web share API:", err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
    }
    render() {
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                {/* <div>
                    <div style={{ marginTop: "2.5rem", textAlign: "right", marginRight: "0.5rem" }}><AiOutlineShareAlt size="20px" onClick={() => this.shareAdv(moment(this.state.createdAt).format("YYYYMMDD"))} /></div>
                    <center><img src={this.state.imageUrl === "uploads/1649942289609advertisement.png" ? config.userUrl + this.state.imageUrl : this.state.imageUrl} className="full-adv" style={{ marginTop: "-0.5rem" }} /></center>
                    <div className="campaign-name">{this.state.campaignName}</div>
                    <p className="terms-sm">{this.state.description}</p>

                    <div className="campaign-url" style={{ wordWrap: "break-word" }}>Url: <span onClick={this.onClickUrl}>{this.state.campaignUrl}</span></div>
                </div>
                <br /><br /><br /> */}
                <div className="detailed-adv-card">
                    <div style={{ marginTop: "1rem", textAlign: "right", marginRight: "1rem" }}><AiOutlineShareAlt size="20px" onClick={() => this.shareAdv(moment(this.state.createdAt).format("YYYYMMDD"))} /></div>
                    <div style={{ border: "3px solid #ffffff", borderRadius: "40px", padding: "10px 4px 16px 4px" }}>
                        <Row style={{ width: "100%" }} className="no-gutters">
                            <Col xs={7}>
                                <div style={{ fontFamily: "Noto Sans" }}>
                                    <div style={{ display: "flex", alignItems: "center", fontWeight: 600 }}>
                                        <img src={advusericon} style={{ width: "40px", marginRight: "8px" }} alt="Advertiser Icon" />
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <span>{this.state.advertiserName}</span>
                                            <p style={{ fontSize: "13px",fontWeight: 500, margin: 0 }}>{this.state.designation}</p>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "1rem", display: "flex", alignItems: "center", fontSize: "12px" }}><img src={advphoneicon} style={{ width: "30px" }} />&nbsp;{this.state.advertiserNumber}</div>
                                    <div style={{ display: "flex", alignItems: "center", fontSize: "12px" }}><img src={advemailicon} style={{ width: "30px" }} /><span className="ml-1">{this.state.advertiserEmail}</span></div>
                                    <div style={{ display: "flex", alignItems: "center", fontSize: "12px" }}><img src={advwebicon} style={{ width: "30px" }} />&nbsp;<span onClick={this.onClickUrl}>{this.state.campaignUrl}</span></div>
                                </div>
                            </Col>
                            <Col xs={5} className="d-flex justify-content-end">
                                <div className="detailed-adv-img-border mr-2">
                                    <div className="adv-image-container">
                                        <img
                                            src={this.state.imageUrl === "uploads/1649942289609advertisement.png" ? config.userUrl + this.state.imageUrl : this.state.imageUrl}
                                            alt="Advertisement"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ borderTop: "3px solid #ffffff", borderRadius: "40px", marginTop: "-2px", padding: "10px 3px" }}>
                        <div className="mt-2 detailed-event-text ml-2 mr-2">{this.state.campaignName}</div>
                        <div className="ml-2 mr-2" style={{ fontSize: "14px", fontFamily: "Noto Sans" }}>{this.state.description}</div>

                    </div>
                </div>

                <BottomMenu />
            </div >
        )
    }
}
